















import Vue from "vue";
export default Vue.extend({
  data: function () {
    return {
      minutes: 0,
      seconds: 0,
      leftTime: 1000,
    };
  },
  props: {
    time: Number,
  },

  mounted: function () {
    const time: number = this.time;
    let leftTime: number = time;
    const countdown = setInterval(() => {
      leftTime = leftTime - 1;
      this.minutes = Math.floor(leftTime / 60);
      this.seconds = leftTime - this.minutes * 60;
      this.leftTime = leftTime;
      if (leftTime < 1) {
        clearInterval(countdown);
      }
    }, 1000);
  },
});
