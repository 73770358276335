

















































import Vue from "vue";
import Timer from "@/components/Timer.vue";
import Footer from "@/components/navigation-footer.vue";
export default Vue.extend({
  data: function () {
    return {
      values: this.$store.state.values.selectedCoreValues,
      groups: this.$store.state.values.groups,
    };
  },
  components: {
    Timer,
    Footer,
  },
  computed: {},

  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
  },
  methods: {
    ToggleValueGroup: function (
      group: { group: number; selected: boolean },
      index: number
    ) {
      if (!group.selected) {
        this.$store.dispatch("SelectGroup", { index: index });
      } else {
        this.$store.dispatch("DeselectGroup", { index: index });
      }
    },
    ToggleValue: function (index: number) {
      if (!this.$store.state.values.selectedCoreValues[index].grouped) {
        this.$store.dispatch("AddGroupValue", { index: index });
      } else {
        this.$store.dispatch("RemoveGroupValue", { index: index });
      }
    },
  },
});
